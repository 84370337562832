const payRangeHelper = (
  hourlyRateTo: string,
  hourlyRateFrom: string,
  showUnit: boolean = false
) => {
  if (hourlyRateTo?.length > 0 && Number(hourlyRateTo) < 10) {
    return '< $10/hr';
  }

  if (hourlyRateFrom?.length > 0 && hourlyRateTo?.length > 0) {
    let str = '';

    if (Number(hourlyRateFrom) === Number(hourlyRateTo)) {
      str = `$${hourlyRateFrom}`;
    } else if (Number(hourlyRateFrom) === 0) {
      str = `>$${hourlyRateTo}`;
    } else {
      str = `$${hourlyRateFrom}-${hourlyRateTo}`;
    }

    return showUnit ? `${str}/hr` : str;
  }

  if (hourlyRateFrom?.length > 0) {
    return `> $${hourlyRateFrom}`;
  }

  return hourlyRateTo?.length > 0 ? `< $${hourlyRateTo}` : '';
};

export default payRangeHelper;
