import { useState } from 'react';
import {
  Button,
  Accordion,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Link,
  Typography,
  Box,
} from '@mui/material';

import {
  IconIllustrationSmallSafetyCheck,
  // eslint-disable-next-line @care/react-icons/deprecated
  Icon24InfoBgcAvailable,
  Icon24InfoBgcFilled,
  Icon24InfoBgcNeutral,
  Icon24UtilityClose,
  // eslint-disable-next-line @care/react-icons/deprecated
  Icon24InfoTransportation,
} from '@care/react-icons';
import { AccordionLabel, AccordionPanel } from '@care/react-component-lib';
import { UsStateCode } from '@/__generated__/globalTypes';
import { SxClassProps } from '@/types/common';
import { usePageAttributes } from './contexts/PageAttributesContext';

const classes: SxClassProps = {
  modalHeader: (theme) => ({
    backgroundColor: theme.palette.care.latte['50'],
    padding: theme.spacing(2, 7),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 3, 1),
    },
  }),
  modalContent: (theme) => ({
    padding: `${theme.spacing(4, 7, 5)} !important`,

    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(4, 3, 5)} !important`,
    },
  }),
  modalFooter: (theme) => ({
    padding: theme.spacing(3, 0, 0),

    '& button': {
      display: 'block',
      margin: '0 auto',
    },
  }),
  headline: (theme) => ({
    flex: '1 1 auto',

    '& h2': {
      [theme.breakpoints.down('md')]: {
        fontSize: '1.3rem',
        lineHeight: '26px',
      },
    },
  }),
  headerIllustration: {
    alignSelf: 'flex-end',
    flex: '0 0 auto',
    height: '104px',
    marginLeft: 1,
    width: '104px',
  },
  safetyCheckIcon: {
    display: 'inline-block',
  },
  bgcContainer: {
    marginTop: 2,
  },
  bgcIcon: {
    flex: '0 0 auto',
    height: '40px',
    marginRight: 2,
    width: '40px',
  },
  bgcContent: {
    flex: '1 1 auto',
  },
  iconList: (theme) => ({
    listStyleType: 'none',
    paddingLeft: 0,
    margin: theme.spacing(2, 0, 3),

    '& li': {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 auto',
      marginBottom: theme.spacing(2),

      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
  iconContainer: {
    backgroundColor: 'care.blue.100',
    borderRadius: 5,
    display: 'inline-block',
    height: '40px',
    marginRight: 2,
    padding: 1,
    width: '40px',
  },
  accordion: (theme) => ({
    backgroundColor: 'care.grey.50',
    borderRadius: '16px',
    marginBottom: 5,
    padding: theme.spacing(0.5, 3),

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  }),
  hiringInMAContent: (theme) => ({
    padding: theme.spacing(0, 2, 0.5),
  }),
  backgroundCheckLink: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginBottom: 3,
  },
  ctaButton: (theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 15,
  },
};

const SafetyModal = (openModal: boolean) => {
  const [open, setOpen] = useState(openModal);
  const { state } = usePageAttributes();

  const shouldShowMAHiringDisclosure = () => {
    return state === UsStateCode.MA;
  };

  const BgcModal = () => (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      PaperProps={{
        sx: (theme) => ({
          [theme.breakpoints.down('md')]: {
            borderRadius: 0,
            bottom: 0,
            margin: 0,
            maxHeight: '100%',
            maxWidth: '100%',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            mx: 0,
          },
        }),
      }}>
      <DialogTitle sx={classes.modalHeader}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item sx={classes.headline}>
            <Typography variant="serifHeadline2" component="h2" align="left">
              Your safety is our priority
            </Typography>
          </Grid>
          <Grid item sx={classes.headerIllustration}>
            <IconIllustrationSmallSafetyCheck size="104px" sx={classes.safetyCheckIcon} />
          </Grid>
        </Grid>
        <IconButton
          size="small"
          onClick={() => {
            setOpen(false);
          }}
          sx={classes.closeButton}>
          <Icon24UtilityClose size="20px" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.modalContent}>
        <Grid
          alignItems="flex-start"
          sx={classes.bgcContainer}
          container
          direction="row"
          wrap="nowrap">
          <Grid item sx={classes.bgcIcon}>
            <Box sx={classes.iconContainer}>
              <Icon24InfoBgcFilled />
            </Box>
          </Grid>
          <Grid item sx={classes.bgcContent}>
            <Typography variant="body2" component="p" mt={0} mb={2}>
              Every caregiver you can hire completes an initial 5-point background check. Caregivers
              are also subject to recurring checks.
            </Typography>
          </Grid>
        </Grid>

        <Accordion sx={classes.accordion} elevation={0}>
          <AccordionLabel primaryLabel="Our initial 5-point background check includes: " />
          <AccordionPanel noPadding>
            <Box component="ul" mt={0} fontSize="body2.fontSize">
              <li>Social Security number trace</li>
              <li>
                National Sex Offender Public Website search <span>*</span>
              </li>
              <li> Multi-jurisdictional criminal database search</li>
              <li> Federal criminal records search</li>
              <li> County criminal records search</li>
            </Box>
          </AccordionPanel>
        </Accordion>

        <Typography variant="h2" component="h2" mb={2}>
          Additional background checks
        </Typography>
        <Typography variant="body2" mb={2}>
          Our initial 5-point background check is the first step in hiring safely. Depending on your
          plan, you can gain access to these additional background checks for free or at an added
          cost, including:
        </Typography>
        <Box component="ul" sx={classes.iconList}>
          <li>
            <Box sx={classes.iconContainer}>
              <Icon24InfoBgcAvailable />
            </Box>
            <Typography variant="body1" component="span">
              Criminal Records Check
            </Typography>
          </li>
          <li>
            <Box sx={classes.iconContainer}>
              <Icon24InfoTransportation />
            </Box>
            <Typography variant="body1" component="span">
              Criminal + Motor Vehicles Records Check
            </Typography>
          </li>
          <li>
            <Box sx={classes.iconContainer}>
              <Icon24InfoBgcNeutral />
            </Box>
            <Typography variant="body1" component="span">
              Investigative Criminal Plus Check
            </Typography>
          </li>
        </Box>
        <Link
          variant="link2"
          component="a"
          sx={classes.backgroundCheckLink}
          href="https://www.care.com/about/safety/background-checks/"
          target="_blank">
          Learn more about the different types of background checks
        </Link>

        {shouldShowMAHiringDisclosure() && (
          <Accordion sx={classes.accordion} elevation={0}>
            <AccordionLabel primaryLabel="Hiring in Massachusetts" />
            <AccordionPanel noPadding>
              <Box sx={classes.hiringInMAContent}>
                <Typography variant="body2">
                  While a CareCheck will search for criminal history information wherever a person
                  has lived in the past 7 years, it does not include a search of all courthouses in
                  Massachusetts. Instead, it includes only the Superior Court and a single District
                  Court in each county where the individual has lived, even if there are multiple
                  District Courts in those counties.
                </Typography>
                <Typography variant="body2">
                  Because the vast majority of Massachusetts criminal records are maintained in
                  District Courts, a CareCheck may not reveal a person&apos;s entire criminal
                  history in this state. if you&apos;re considering a caregiver who has lived in
                  Massachusetts, we recommend you run a statewide search of the Criminal Offender
                  Record Information (CORI) system, which is more comprehensive than a CareCheck
                  country criminal search at this time. The cost to run a caregiver Required 2 CORI
                  search is $25.
                </Typography>
                <Typography variant="body2">
                  You can also view a list of criminal offenses that are commonly addressed by the
                  District Courts.
                </Typography>
              </Box>
            </AccordionPanel>
          </Accordion>
        )}
        <Typography variant="body3" mb={3}>
          * As with all background checks, sex offender registry checks are subject to data and
          reporting limitations and may not reveal an individual’s entire offense history.
        </Typography>
        <Box sx={classes.modalFooter}>
          <Button
            color="secondary"
            sx={classes.ctaButton}
            onClick={() => {
              setOpen(false);
            }}
            size="medium"
            variant="contained">
            Got it
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
  return [BgcModal, setOpen, open] as const;
};
export default SafetyModal;
