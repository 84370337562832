import { useState } from 'react';
import { Modal } from '@care/react-component-lib';
import { Icon24InfoSparkle } from '@care/react-icons';
import { Box, Button, useMediaQuery, Link, Theme } from '@mui/material';
import { AmplitudeEvent, trackAmplitudeEvent } from '@/lib/analyticsHelper';
import { ScreenName } from '@/constants';

const AssistedBioIndicator = () => {
  const [showAssistedBioModal, setAssistedBioModal] = useState(false);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleAssistedBioClick = () => {
    setAssistedBioModal(true);

    trackAmplitudeEvent('CTA Interacted', {
      screen_name: ScreenName.PROVIDER,
      cta_text: 'Assisted bio',
      cta_intent: 'Click on assisted bio badge',
    });

    trackAmplitudeEvent(AmplitudeEvent.providerProfileViewed, {
      screen_name: ScreenName.AI_BIO,
    });
  };

  const closeAssistedBioModal = () => {
    setAssistedBioModal(false);

    trackAmplitudeEvent('CTA Interacted', {
      screen_name: ScreenName.AI_BIO,
      cta_text: 'OK',
      cta_intent: 'Dismiss assisted bio modal',
    });
  };

  const handleLearnMoreClick = () => {
    trackAmplitudeEvent('CTA Interacted', {
      screen_name: ScreenName.PROVIDER,
      cta_text: 'Learn more',
      cta_intent: 'Click to learn more about assisted bio',
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" mt={1.5}>
        <Icon24InfoSparkle />
        <Button
          variant="text"
          sx={{ height: '30px', fontWeight: '400', fontSize: 'info1.fontSize' }}
          onClick={handleAssistedBioClick}
          size="large">
          Assisted bio
        </Button>
      </Box>
      <Modal
        open={showAssistedBioModal}
        onClose={closeAssistedBioModal}
        title="What is an assisted bio?">
        This caregiver wrote their bio using our AI tool, which summarizes their background based on
        their answers to questions about their skills and experience.
        <Button
          color="secondary"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={closeAssistedBioModal}>
          OK
        </Button>
        <Link
          sx={{ fontWeight: '700' }}
          variant="body2"
          href="https://help.care.com/families/s/article/What-is-an-Assisted-bio?language=en_US"
          onClick={handleLearnMoreClick}
          target={isDesktop ? '_blank' : '_self'}>
          Learn more
        </Link>
      </Modal>
    </>
  );
};

export default AssistedBioIndicator;
