// Write tests for these and use them in getServerSideProps

import { ParsedUrlQuery } from 'querystring';

export const getSegmentUrl = (query: ParsedUrlQuery) => {
  const segmentURL = query.segment?.toString();

  const pageStringArr = segmentURL?.split('-page') || ''; // remove anything after -page
  return `/${pageStringArr[0]}`;
};

export const getVerticalUrl = (query: ParsedUrlQuery) => {
  const verticalURL = query.vertical?.toString();

  return `${verticalURL}`;
};

export const getPageNumber = (query: ParsedUrlQuery) => {
  const segmentURL = query.segment?.toString() || '';
  let page = 1;

  const urlPageNumber = segmentURL?.replace(/^\D+/g, ''); // find number
  if (urlPageNumber.length > 0) {
    page = Number(urlPageNumber);
  }

  return page;
};
