import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { getSEOSegmentationBreadcrumbs_getSEOSegmentationBreadcrumbs_breadcrumbs as breadcrumbsType } from '@/__generated__/getSEOSegmentationBreadcrumbs';

export interface BreadcrumbsProp {
  breadcrumbs: breadcrumbsType[];
}

interface BaseEntity {
  '@type': string;
}

interface Entity extends BaseEntity {
  position: number;
}

interface Item {
  '@id': string;
  name: string;
}

interface ItemListElement extends Entity {
  item: Item;
}

interface LdJson extends BaseEntity {
  '@context': string;
  itemListElement: ItemListElement[];
}

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProp) => {
  const schemaJson: LdJson = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  };

  const formatJSON = (breadcrumb: breadcrumbsType, index: number) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': `${breadcrumb.url}`,
        name: `${breadcrumb.content}`,
      },
    };
  };

  const createBreadcrumbSchema = () => {
    schemaJson.itemListElement =
      breadcrumbs.map((breadcrumb, index) => formatJSON(breadcrumb, index)) || [];
    return schemaJson;
  };
  return (
    <>
      <MuiBreadcrumbs>
        {breadcrumbs.map(
          ({ content, url }, index) =>
            (index + 1 !== breadcrumbs.length && (
              <Link component="a" key={content} href={url} variant="body2">
                {content}
              </Link>
            )) ||
            (index + 1 === breadcrumbs.length && (
              <Typography role="heading" variant="body2" key={content}>
                {content}
              </Typography>
            ))
        )}
      </MuiBreadcrumbs>
      <script
        data-testid="breadcrumbListSchema"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(createBreadcrumbSchema()) }}
      />
    </>
  );
};

export default Breadcrumbs;
